<template>
  <v-container>
    <v-expansion-panels mandatory>
      <v-expansion-panel>
        <v-expansion-panel-header> <div class="header-text">Trip Time Messages</div></v-expansion-panel-header>
        <v-expansion-panel-content>
          <trip-time-messages
            @formChanges="(value) => controlNavigation(value, 'tripTimeMessages')"
          ></trip-time-messages>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Blocked Dates</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <blocked-dates @formChanges="(value) => controlNavigation(value, 'blockedDates')"></blocked-dates>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Special Dates</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <special-dates @formChanges="(value) => controlNavigation(value, 'specialDates')"></special-dates>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="header-text">Lead Days</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <lead-days @formChanges="(value) => controlNavigation(value, 'leadDays')"></lead-days>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import TripTimeMessages from './TripTimeMessages.vue';
import BlockedDates from './BlockedDates.vue';
import SpecialDates from './SpecialDates.vue';
import LeadDays from './LeadDays.vue';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { isEqual } from 'lodash';

export default {
  mixins: [promptBeforeLeave],
  components: { TripTimeMessages, BlockedDates, SpecialDates, LeadDays },
  data: () => ({
    config: {
      leadDays: {},
    },
    formsChanged: {
      tripTimeMessages: false,
      blockedDates: false,
      specialDates: false,
      leadDays: false,
    },
  }),
  watch: {
    formsChanged: {
      handler(value) {
        this.baseFormValue = { success: true };
        if (Object.values(value).some((changed) => changed)) this.latestFormValue = { success: false };
        else this.latestFormValue = { success: true };
      },
      deep: true,
    },
  },
  methods: {
    controlNavigation(value, formName) {
      const baseForm = value.baseFormValue ?? null;
      const latestForm = value.latestFormValue ?? null;
      this.formsChanged[formName] = !isEqual(baseForm, latestForm);
    },
  },
};
</script>

<style scoped>
.header-text {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
}
</style>
