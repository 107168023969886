<template>
  <v-form :readonly="!canEditSettings">
    <v-row dense>
      <v-col cols="12" md="12">
        <v-btn color="green" dark @click="save" class="save-btn" v-if="canEditSettings"> save </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-card>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  label="Prevent Submission Message"
                  v-model="config.preventSubmitMessage"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-textarea outlined label="Warning Message" v-model="config.warningMessage" rows="3"></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="6">
                <v-card>
                  <v-card-title>General Trip Requests</v-card-title>
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" md="12">
                        <v-radio-group v-model="config.general.type" row>
                          <v-radio label="Warning Only" value="warning"></v-radio>
                          <v-radio label="Prevent Submission" value="prevent"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="3">
                        <v-text-field label="Lead Days" v-model="config.general.numDays" outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <h5>Trip Types</h5>
                    <v-row dense>
                      <v-col cols="12" md="3" class="checkbox">
                        <v-checkbox v-model="selectAllGenTypes" label="All" @change="handleAllGenTypes"></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="3" v-for="(type, i) in tripTypes" :key="i" class="checkbox">
                        <v-checkbox
                          :value="type.id"
                          v-model="config.general.tripTypeIds"
                          :label="type.name"
                          multiple
                          @click="handleTripTypeSelected"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <h5>Trip Events</h5>
                    <v-row dense>
                      <v-col cols="12" md="3" class="checkbox" v-if="genFilteredTripEvents.length">
                        <v-checkbox v-model="selectAllGenEvents" label="All" @change="handleAllGenEvents"></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="3" v-for="(event, i) in genFilteredTripEvents" :key="i" class="checkbox">
                        <v-checkbox
                          :value="event.id"
                          v-model="config.general.tripEventIds"
                          :label="event.name"
                          multiple
                          @click="handleTripEventSelected"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card>
                  <v-card-title>Overnight or Out of State Requests</v-card-title>
                  <v-container>
                    <v-row dense>
                      <v-col cols="12" md="12">
                        <v-radio-group v-model="config.oos.type" row>
                          <v-radio label="Warning Only" value="warning"></v-radio>
                          <v-radio label="Prevent Submission" value="prevent"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="3">
                        <v-text-field label="Lead Days" v-model="config.oos.numDays" outlined></v-text-field>
                      </v-col>
                    </v-row>
                    <h5>Trip Types</h5>
                    <v-row dense>
                      <v-col cols="12" md="3" class="checkbox">
                        <v-checkbox v-model="selectAllOOSTypes" label="All" @change="handleAllOOSTypes"></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="3" v-for="(type, i) in tripTypes" :key="i" class="checkbox">
                        <v-checkbox
                          :value="type.id"
                          v-model="config.oos.tripTypeIds"
                          :label="type.name"
                          multiple
                          @click="handleTripTypeSelected"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <h5>Trip Events</h5>
                    <v-row dense>
                      <v-col cols="12" md="3" class="checkbox" v-if="oosFilteredTripEvents.length">
                        <v-checkbox v-model="selectAllOOSEvents" label="All" @change="handleAllOOSEvents"></v-checkbox>
                      </v-col>
                      <v-col cols="12" md="3" v-for="(event, i) in oosFilteredTripEvents" :key="i" class="checkbox">
                        <v-checkbox
                          :value="event.id"
                          v-model="config.oos.tripEventIds"
                          :label="event.name"
                          multiple
                          @click="handleTripEventSelected"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';

import { cloneDeep } from 'lodash';

export default {
  mixins: [],
  components: {},
  props: {},
  data: () => ({
    config: {
      preventSubmitMessage: '',
      warningMessage: '',
      general: {
        type: 'warning',
        numDays: null,
        tripTypeIds: [],
        tripEventIds: [],
      },
      oos: {
        type: 'warning',
        numDays: null,
        tripTypeIds: [],
        tripEventIds: [],
      },
    },
    genFilteredTripEvents: [],
    oosFilteredTripEvents: [],
    selectAllGenTypes: false,
    selectAllGenEvents: false,
    selectAllOOSTypes: false,
    selectAllOOSEvents: false,
    baseFormValue: null,
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
    ...mapGetters('tripType', ['tripTypes', 'tripTypesById']),
    ...mapGetters('tripEvent', ['tripEvents', 'tripEventsById']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.ldConfig = await this.getConfig('leadDays');
      if (this.ldConfig) this.config = this.ldConfig;
      this.handleTripTypeSelected();
      this.baseFormValue = cloneDeep(this.config);
    },
    handleTripTypeSelected() {
      this.genFilteredTripEvents = this.tripEvents.filter((e) =>
        this.config.general.tripTypeIds.includes(e.tripTypeId)
      );
      this.config.general.tripEventIds.filter((e) => this.genFilteredTripEvents.map((f) => f.id).includes(e));

      this.oosFilteredTripEvents = this.tripEvents.filter((e) => this.config.oos.tripTypeIds.includes(e.tripTypeId));
      this.config.oos.tripEventIds = this.config.oos.tripEventIds.filter((e) =>
        this.oosFilteredTripEvents.map((f) => f.id).includes(e)
      );

      if (this.config.general.tripTypeIds.length == this.tripTypes.length) this.selectAllGenTypes = true;
      else this.selectAllGenTypes = false;
      if (this.config.oos.tripTypeIds.length == this.tripTypes.length) this.selectAllOOSTypes = true;
      else this.selectAllOOSTypes = false;

      this.handleTripEventSelected();
    },
    handleTripEventSelected() {
      this.config.general.tripEventIds = this.config.general.tripEventIds.filter((e) =>
        this.config.general.tripTypeIds.includes(this.tripEventsById[e].tripTypeId)
      );
      this.config.oos.tripEventIds = this.config.oos.tripEventIds.filter((e) =>
        this.config.oos.tripTypeIds.includes(this.tripEventsById[e].tripTypeId)
      );

      if (this.config.general.tripEventIds.length == this.genFilteredTripEvents.length) this.selectAllGenEvents = true;
      else this.selectAllGenEvents = false;
      if (this.config.oos.tripEventIds.length == this.oosFilteredTripEvents.length) this.selectAllOOSEvents = true;
      else this.selectAllOOSEvents = false;
    },
    handleAllOOSTypes() {
      if (this.config.oos.tripTypeIds.length == this.tripTypes.length) this.config.oos.tripTypeIds = [];
      else this.config.oos.tripTypeIds = this.tripTypes.map((e) => e.id);
      this.handleTripTypeSelected();
    },
    handleAllOOSEvents() {
      if (this.config.oos.tripEventIds.length == this.oosFilteredTripEvents.length) this.config.oos.tripEventIds = [];
      else this.config.oos.tripEventIds = this.oosFilteredTripEvents.map((e) => e.id);
      this.handleTripEventSelected();
    },
    handleAllGenTypes() {
      if (this.config.general.tripTypeIds.length == this.tripTypes.length) this.config.general.tripTypeIds = [];
      else this.config.general.tripTypeIds = this.tripTypes.map((e) => e.id);
      this.handleTripTypeSelected();
    },
    handleAllGenEvents() {
      if (this.config.general.tripEventIds.length == this.genFilteredTripEvents.length)
        this.config.general.tripEventIds = [];
      else this.config.general.tripEventIds = this.genFilteredTripEvents.map((e) => e.id);
      this.handleTripEventSelected();
    },
    async save() {
      try {
        const r = await this.setConfig({ name: 'leadDays', value: { ...this.ldConfig, ...this.config } });
        if (r && r.done) {
          this.$emit('formChanges', { baseFormValue: true, latestFormValue: true });
          this.baseFormValue = cloneDeep(this.config);
          this.$myalert.success('Lead Days saved');
        }
      } catch (error) {
        this.$myalert.error(error.description);
      }
    },
  },
  watch: {
    config: {
      handler(value) {
        this.$emit('formChanges', { latestFormValue: cloneDeep(value), baseFormValue: cloneDeep(this.baseFormValue) });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
.save-btn {
  float: right;
  margin-bottom: 12px;
}
</style>
