var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { attrs: { readonly: !_vm.canEditSettings } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _vm.canEditSettings
                ? _c(
                    "v-btn",
                    {
                      staticClass: "save-btn",
                      attrs: { color: "green", dark: "" },
                      on: { click: _vm.save },
                    },
                    [_vm._v(" save ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  label: "Prevent Submission Message",
                                  rows: "3",
                                },
                                model: {
                                  value: _vm.config.preventSubmitMessage,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.config,
                                      "preventSubmitMessage",
                                      $$v
                                    )
                                  },
                                  expression: "config.preventSubmitMessage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "12" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  outlined: "",
                                  label: "Warning Message",
                                  rows: "3",
                                },
                                model: {
                                  value: _vm.config.warningMessage,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.config, "warningMessage", $$v)
                                  },
                                  expression: "config.warningMessage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v("General Trip Requests"),
                                  ]),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value:
                                                      _vm.config.general.type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.general,
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "config.general.type",
                                                  },
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "Warning Only",
                                                      value: "warning",
                                                    },
                                                  }),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label:
                                                        "Prevent Submission",
                                                      value: "prevent",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Lead Days",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.config.general.numDays,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config.general,
                                                      "numDays",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.general.numDays",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("h5", [_vm._v("Trip Types")]),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "checkbox",
                                              attrs: { cols: "12", md: "3" },
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: { label: "All" },
                                                on: {
                                                  change: _vm.handleAllGenTypes,
                                                },
                                                model: {
                                                  value: _vm.selectAllGenTypes,
                                                  callback: function ($$v) {
                                                    _vm.selectAllGenTypes = $$v
                                                  },
                                                  expression:
                                                    "selectAllGenTypes",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.tripTypes,
                                            function (type, i) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: i,
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      value: type.id,
                                                      label: type.name,
                                                      multiple: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleTripTypeSelected,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.config.general
                                                          .tripTypeIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.config.general,
                                                          "tripTypeIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "config.general.tripTypeIds",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c("h5", [_vm._v("Trip Events")]),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _vm.genFilteredTripEvents.length
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: { label: "All" },
                                                    on: {
                                                      change:
                                                        _vm.handleAllGenEvents,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectAllGenEvents,
                                                      callback: function ($$v) {
                                                        _vm.selectAllGenEvents =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectAllGenEvents",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.genFilteredTripEvents,
                                            function (event, i) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: i,
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      value: event.id,
                                                      label: event.name,
                                                      multiple: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleTripEventSelected,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.config.general
                                                          .tripEventIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.config.general,
                                                          "tripEventIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "config.general.tripEventIds",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c("v-card-title", [
                                    _vm._v(
                                      "Overnight or Out of State Requests"
                                    ),
                                  ]),
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c(
                                                "v-radio-group",
                                                {
                                                  attrs: { row: "" },
                                                  model: {
                                                    value: _vm.config.oos.type,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.config.oos,
                                                        "type",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "config.oos.type",
                                                  },
                                                },
                                                [
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label: "Warning Only",
                                                      value: "warning",
                                                    },
                                                  }),
                                                  _c("v-radio", {
                                                    attrs: {
                                                      label:
                                                        "Prevent Submission",
                                                      value: "prevent",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Lead Days",
                                                  outlined: "",
                                                },
                                                model: {
                                                  value: _vm.config.oos.numDays,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.config.oos,
                                                      "numDays",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "config.oos.numDays",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("h5", [_vm._v("Trip Types")]),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "checkbox",
                                              attrs: { cols: "12", md: "3" },
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: { label: "All" },
                                                on: {
                                                  change: _vm.handleAllOOSTypes,
                                                },
                                                model: {
                                                  value: _vm.selectAllOOSTypes,
                                                  callback: function ($$v) {
                                                    _vm.selectAllOOSTypes = $$v
                                                  },
                                                  expression:
                                                    "selectAllOOSTypes",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.tripTypes,
                                            function (type, i) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: i,
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      value: type.id,
                                                      label: type.name,
                                                      multiple: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleTripTypeSelected,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.config.oos
                                                          .tripTypeIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.config.oos,
                                                          "tripTypeIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "config.oos.tripTypeIds",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c("h5", [_vm._v("Trip Events")]),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _vm.oosFilteredTripEvents.length
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: { label: "All" },
                                                    on: {
                                                      change:
                                                        _vm.handleAllOOSEvents,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectAllOOSEvents,
                                                      callback: function ($$v) {
                                                        _vm.selectAllOOSEvents =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectAllOOSEvents",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.oosFilteredTripEvents,
                                            function (event, i) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: i,
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    cols: "12",
                                                    md: "3",
                                                  },
                                                },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      value: event.id,
                                                      label: event.name,
                                                      multiple: "",
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleTripEventSelected,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.config.oos
                                                          .tripEventIds,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.config.oos,
                                                          "tripEventIds",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "config.oos.tripEventIds",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }