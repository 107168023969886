<template>
  <v-form :readonly="!canEditSettings">
    <v-row dense>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn fab small color="primary" @click="addBD()" v-if="!editedBD && canEditSettings">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn v-else v-show="editedBD" fab small color="primary" @click="addBD()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-scroll-x-transition>
          <v-btn v-show="selected.length" class="mx-2" dark color="red" @click="deleteBD()" v-if="canEditSettings">
            <v-icon>mdi-delete</v-icon>Delete {{ selected.length }} Blocked Date{{ selected.length > 1 ? 's' : '' }}
          </v-btn>
        </v-scroll-x-transition>
      </v-col>
    </v-row>
    <v-row dense v-if="editedBD">
      <v-col cols="12" md="12">
        <v-card>
          <v-container>
            <v-row dense>
              <v-col cols="12" md="6">
                <date-picker
                  label="Begin Date"
                  :value="editedBD.begin"
                  v-model="$v.form.begin.$model"
                  required
                  :readonly="!canEditSettings"
                  :error-descriptions="handleErrors($v.form.begin)"
                  @blur="$v.form.begin.$touch()"
                ></date-picker>
              </v-col>
              <v-col cols="12" md="6">
                <date-picker
                  label="End Date"
                  :value="editedBD.end"
                  v-model="$v.form.end.$model"
                  required
                  :readonly="!canEditSettings"
                  :error-descriptions="handleErrors($v.form.end)"
                  @blur="$v.form.end.$touch()"
                ></date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-textarea
                  outlined
                  label="Description"
                  :value="editedBD.description"
                  v-model="$v.form.description.$model"
                  required
                  :error-descriptions="handleErrors($v.form.description)"
                  @blur="$v.form.description.$touch()"
                ></v-textarea>
              </v-col>
            </v-row>
            <h5>Trip Types</h5>
            <v-row dense>
              <v-col cols="12" md="3" class="checkbox">
                <v-checkbox v-model="selectAllTypes" label="All" @change="handleAllTypes"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" v-for="(type, i) in tripTypes" :key="i" class="checkbox">
                <v-checkbox
                  :value="type.id"
                  v-model="$v.form.tripTypeIds.$model"
                  :label="type.name"
                  @change="$v.form.tripTypeIds.$touch()"
                  multiple
                  @click="handleTripTypeSelected"
                ></v-checkbox>
              </v-col>
            </v-row>
            <h5 v-if="filteredTripEvents.length">Trip Events</h5>
            <v-row dense v-if="filteredTripEvents.length">
              <v-col cols="12" md="3" class="checkbox">
                <v-checkbox v-model="selectAllEvents" label="All" @change="handleAllEvents"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" v-for="(event, i) in filteredTripEvents" :key="i" class="checkbox">
                <v-checkbox
                  :value="event.id"
                  v-model="$v.form.tripEventIds.$model"
                  :label="event.name"
                  @change="$v.form.tripEventIds.$touch()"
                  multiple
                  @click="handleTripEventSelected"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-btn color="green" dark @click="save" class="save-btn" v-if="canEditSettings"> save </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="12">
        <v-data-table
          @click:row="editBD($event)"
          fixed-header
          :headers="headers"
          :items="blockedDates"
          item-key="id"
          show-select
          v-model="selected"
        >
          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <v-simple-checkbox :value="isSelected" @input="select($event)" v-ripple></v-simple-checkbox>
          </template>
          <template #[`item.tripTypes`]="{ item }">
            <p class="mb-0">{{ item.tripTypeIds.map((e) => tripTypesById[e].name).join(', ') }}</p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { handleErrors } from '@/util';
import { mapActions, mapGetters } from 'vuex';
import { GET_TRIP_DATE_TIMES, SAVE_TRIP_DATE_TIME, DELETE_TRIP_DATE_TIMES } from '@/store/modules/Config/actions';
import DatePicker from '@/components/DatePicker.vue';
import { cloneDeep } from 'lodash';

const newBD = {
  type: 'blocked',
  begin: '',
  end: '',
  description: '',
  tripTypeIds: [],
  tripEventIds: [],
};

export default {
  mixins: [validationMixin],
  components: { DatePicker },
  props: {},
  data: () => ({
    handleErrors,
    blockedDates: [],
    id: null,
    form: newBD,
    headers: [
      { text: 'Begin Date', value: 'begin' },
      { text: 'End Date', value: 'end' },
      { text: 'Description', value: 'description' },
      { text: 'Trip Types', value: 'tripTypes' },
    ],
    editedBD: null,
    selected: [],
    filteredTripEvents: [],
    selectAllTypes: false,
    selectAllEvents: false,
    baseFormValue: null,
  }),
  validations: {
    form: {
      begin: { required },
      end: { required },
      description: { required },
      tripTypeIds: { required },
      tripEventIds: { required },
    },
  },
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
    ...mapGetters('tripType', ['tripTypes', 'tripTypesById']),
    ...mapGetters('tripEvent', ['tripEvents', 'tripEventsById']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_TRIP_DATE_TIMES, SAVE_TRIP_DATE_TIME, DELETE_TRIP_DATE_TIMES]),
    async fetchItems() {
      this.blockedDates = await this.getTripDateTimes('blocked');
    },
    clear() {
      this.form = newBD;
      this.$refs.aform.clear();
    },
    addBD() {
      if (!this.editedBD) {
        this.editedBD = { id: 0, ...newBD };
        this.baseFormValue = cloneDeep(this.editedBD);
      } else this.editedBD = null;
    },
    editBD(bd) {
      this.editedBD = bd;
      this.$v.form.$model = this.editedBD;
      this.handleTripTypeSelected();
      this.baseFormValue = cloneDeep(this.editedBD);
    },
    handleTripTypeSelected() {
      this.filteredTripEvents = this.tripEvents.filter((e) => this.$v.form.tripTypeIds.$model.includes(e.tripTypeId));
      this.$v.form.tripEventIds.$model.filter((e) => this.filteredTripEvents.map((f) => f.id).includes(e));

      if (this.$v.form.tripTypeIds.$model.length == this.tripTypes.length) this.selectAllTypes = true;
      else this.selectAllTypes = false;
      this.handleTripEventSelected();
    },
    handleTripEventSelected() {
      this.$v.form.tripEventIds.$model = this.$v.form.tripEventIds.$model.filter((e) =>
        this.$v.form.tripTypeIds.$model.includes(this.tripEventsById[e].tripTypeId)
      );

      if (this.$v.form.tripEventIds.$model.length == this.filteredTripEvents.length) this.selectAllEvents = true;
      else this.selectAllEvents = false;
    },
    handleAllTypes() {
      if (this.$v.form.tripTypeIds.$model.length == this.tripTypes.length) this.$v.form.tripTypeIds.$model = [];
      else this.$v.form.tripTypeIds.$model = this.tripTypes.map((e) => e.id);
      this.handleTripTypeSelected();
    },
    handleAllEvents() {
      if (this.$v.form.tripEventIds.$model.length == this.filteredTripEvents.length)
        this.$v.form.tripEventIds.$model = [];
      else this.$v.form.tripEventIds.$model = this.filteredTripEvents.map((e) => e.id);
      this.handleTripEventSelected();
    },
    async save() {
      if (this.$v.form.$invalid) {
        this.$myalert.error('Please complete all fields');
        return;
      }
      try {
        const r = await this.saveTripDateTime({
          ...this.$v.form.$model,
          tripTypeIds: this.$v.form.tripTypeIds.$model.filter((e) => e), // prevent 0s in ids array
          tripEventIds: this.$v.form.tripEventIds.$model.filter((e) => e), // prevent 0s in ids array
        });
        if (r && r.id) {
          this.$myalert.success('Blocked Date saved');
          this.editedBD = null;
          await this.fetchItems();
        }
      } catch (error) {
        this.$myalert.error(error.description);
      }
    },
    async deleteBD() {
      const yes = await this.$myconfirm(
        `Are you sure you want to delete ${
          this.selected.length > 1 ? 'these Blocked Dates' : 'this Blocked Date'
        }? This operation cannot be undone.`
      );
      if (yes) {
        const r = await this.deleteTripDateTimes(this.selected.map((e) => e.id));
        if (r.done) {
          this.$myalert.success(`${this.selected.length > 1 ? 'Blocked Dates' : 'Blocked Date'} deleted`);
          this.editedBD = null;
          this.selected = [];
          this.fetchItems();
        }
      }
    },
  },
  watch: {
    editedBD: {
      immediate: true,
      deep: true,
      handler: function (editedBD) {
        if (editedBD === null) {
          this.baseFormValue = cloneDeep({ ...newBD, ...editedBD });
        }
        this.form = { ...newBD, ...editedBD };
      },
    },
    form: {
      deep: true,
      handler: function (value) {
        this.$emit('formChanges', { latestFormValue: cloneDeep(value), baseFormValue: cloneDeep(this.baseFormValue) });
      },
    },
  },
};
</script>

<style scoped>
.checkbox {
  display: flex;
  align-items: center !important;
}
</style>
